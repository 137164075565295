import Vue from 'vue'
import Vuex from 'vuex'
import types from './types'
// import mocks from './mocks/mockups.js'
import Cookies from 'js-cookie'
import api from './api/api.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userLanguageId: 'no',
    activeComponent: 'enterEmail',
    selectedUserEmail: undefined,
    cookieAlert: true
  },
  mutations: {
    [types.SET_COOKIE_ALERT]: async function (state, payload) {
      state.cookieAlert = payload
    },
    [types.SET_USER_LANGUAGEID]: async function (state, payload) {
      state.userLanguageId = payload
    },
    [types.SET_SELECTED_USER_EMAIL]: async function (state, payload) {
      state.selectedUserEmail = payload
    },
    [types.SET_ACCEPT_COOKIE]: async function (state) {
      Cookies.set('EnviDan.cookieAccept', { expires: 1825 })
    },
    [types.SET_EMAIL_COOKIE]: async function (state, email) {
      Cookies.set('EnviDan.identityEmailToken', email, { expires: 1825 })
    },
    [types.SET_LANG_COOKIE]: async function (state, languageId) {
      Cookies.set('EnviDan.languageToken', languageId, { expires: 1825 })
    },
    [types.SET_TO_ENTER_EMAIL_STEP]: async function (state) {
      state.activeComponent = 'enterEmail'
    },
    [types.SET_TO_ENTER_PASSWORD_STEP]: async function (state) {
      state.activeComponent = 'enterPassword'
    },
    [types.SET_TO_ENTER_FORGOTTEN_PASSWORD_STEP]: async function (state) {
      state.activeComponent = 'forgottenPassword'
    },
    [types.SET_RETURNURL]: async function (state, payload) {
      state.returnUrl = payload
    }
  },
  actions: {
    // If agree to cookies, set cookie to remember agreement and set alert to false
    setAcceptCookie: async function ({ commit }) {
      commit(types.SET_ACCEPT_COOKIE)
      commit(types.SET_COOKIE_ALERT, false)
    },
    setUserLanguageId: async function ({ commit }, languageId) {
      commit(types.SET_USER_LANGUAGEID, languageId)
      commit(types.SET_LANG_COOKIE, languageId)
    },
    // email from enterEmail.vue added to state as user and then cookie for enterPassword.vue, then redirect to enterPassword.vue component
    checkSelectedUserEmail: async function ({ commit }, { email }) {
    // Using async wraps the result of checkSelectedUserEmail with a Promise.
    // If you want that wrapping promise to resolve to something, just return it from the async function. If you want the wrapping promise to be rejected, throw an error inside the async function.
      const value = await api.get('user/' + email)
      if (value) {
        return 'User exsist'
      }
      throw Error('Ukjent e-post')
    },
    // email from enterEmail.vue added to state as user and then cookie for enterPassword.vue, then redirect to enterPassword.vue component
    setSelectedUserEmail: async function ({ commit, dispatch }, { email, rememberMe }) {
      // user added to state
      commit(types.SET_SELECTED_USER_EMAIL, email)
      // set cookie if checkbox is active
      if (rememberMe) {
        commit(types.SET_EMAIL_COOKIE, email)
      }
      // change component in loginPage.vue
      dispatch('setToEnterPasswordStep')
    },
    setSelectedLogin: async function ({ commit, dispatch, state }, { password }) {
      const result = await api.post('account/login', { password: password, username: state.selectedUserEmail, returnUrl: state.returnUrl })
      if (result && result.data.isOk) {
        return result
      } else {
        throw Error('Login denied')
      }
    },
    // if remember me - cookie exist then set selectedUserEmail and activeComponent in state
    // also removes cookie information if accepted
    loginState: async function ({ commit }) {
      let cookieEmailValue = Cookies.get('EnviDan.identityEmailToken')
      if (cookieEmailValue) {
        commit(types.SET_SELECTED_USER_EMAIL, cookieEmailValue)
        commit(types.SET_TO_ENTER_PASSWORD_STEP)
      }
      let cookieLangValue = Cookies.get('EnviDan.languageToken')
      if (cookieLangValue) {
        commit(types.SET_USER_LANGUAGEID, cookieLangValue)
      }
      let cookieAcceptValue = Cookies.get('EnviDan.cookieAccept')
      if (cookieAcceptValue) {
        commit(types.SET_COOKIE_ALERT, false)
      }
    },
    // set loginPage component to enterPassword.vue through state
    setToEnterPasswordStep: async function ({ commit, state }) {
      if (state.selectedUserEmail) {
        commit(types.SET_TO_ENTER_PASSWORD_STEP)
        return ''
      } else {
        return 'Unknown User'
      }
    },
    // set loginPage component to forgottenPassword.vue through state
    setToEnterForgottenPasswordStep: async function ({ commit, state }) {
      if (state.selectedUserEmail) {
        commit(types.SET_TO_ENTER_FORGOTTEN_PASSWORD_STEP)
        return ''
      } else {
        return 'Unknown User'
      }
    },
    sendResetPasswordEmail: async function ({ commit, state }) {
      // Using async wraps the result of checkSelectedUserEmail with a Promise.
      // If you want that wrapping promise to resolve to something, just return it from the async function. If you want the wrapping promise to be rejected, throw an error inside the async function.
      const result = await api.post('account/forgotpassword', { email: state.selectedUserEmail })
      if (result && result.data.isOk) {
        return 'User exsist'
      }
      throw Error('User unknown')
    },
    // set loginPage component to enterEmail.vue through state
    setToEnterEmailStep: async function ({ commit }) {
      commit(types.SET_TO_ENTER_EMAIL_STEP)
    },
    setReturnUrl: async function ({ commit }, { returnUrl }) {
      // set returnUrl received in querystring
      commit(types.SET_RETURNURL, returnUrl)
    },
    setResetPassword: async function ({ commit, dispatch, state }, { email, password, confirmpassword, code }) {
      // console.log('setResetPassword')
      // window.location = result.data.re
      const result = await api.post('account/ResetPassword', { email: email, password: password, confirmpassword: confirmpassword, code: code })
      //  console.log(result)
      if (result && result.data.isOk) {
      //  console.log('Password was reset')
        // window.location = result.data.redirectUrl // this will redirect back to IS4, which will then redirect back to origin
        return result
      } else {
        throw Error('Password was not reset')
      }
    }
  },
  getters: {
    getCookieAlert: state => {
      return state.cookieAlert
    },
    getUserLanguageId: state => {
      return state.userLanguageId
    },
    getSelectedUserEmail: state => {
      return state.selectedUserEmail
    }
  }
})
