<template>
  <v-app>
    <v-content>
      <router-view/>
      <vue-progress-bar></vue-progress-bar>
    </v-content>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data: () => ({
  })
}
</script>

<style>
  html {
    overflow-y: hidden !important;
  }
  body {
    margin: 0px;
  }
  @import './assets/fonts/webfonts.scss';
</style>
