import VueI18n from 'vue-i18n'
import Vue from 'vue'
import api from '../api/api.js'

Vue.use(VueI18n)

const loadedLanguages = [] // our default language that is preloaded

export async function loadLanguageAsync (lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      let labelsResponse = await api.get('/Translation/' + lang)
      let translations = { ...labelsResponse.data }
      i18n.setLocaleMessage(lang, translations)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}

function setI18nLanguage (lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

const dateTimeFormats = {
  'da-DK': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    }
  }
}

export const i18n = new VueI18n({
  locale: 'da',
  messages: {},
  silentTranslationWarn: true,
  dateTimeFormats
})
