<template lang="pug">
  div.enterPasswordComponent
    v-card-subtitle.py-0 {{ getSelectedUserEmail }}
    v-card-title {{'Skriv inn passord'}}
    v-card-text
      v-text-field.password(
        :label="'Passord'"
        v-model='password'
        :error-messages="passwordError"
        :placeholder="'Skriv inn passord'"
        type="password"
        autofocus
        @keyup.enter="loginto"
      )
      a.underlined(color="primary" @click="forgottenPassword") {{'Glemt passord'}}
    v-card-actions.px-4.pb-4
      v-btn.px-4(small rounded elevation="0" type="submit" color="primary" @click="backToEmail") {{'Tilbake'}}
      div.flex-grow-1
      v-btn.loginbtn.px-4(small rounded elevation="0" type="submit" color="primary" @click="loginto" :disabled="debounced") {{'Login'}}
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'enter-password',
  components: {
  },
  data: function () {
    return {
      debounced: false,
      passwordError: '',
      password: ''
    }
  },
  methods: {
    loginto () {
      // set button to disabled if action is running
      this.debounced = true
      this.$store.dispatch('setSelectedLogin', { password: this.password }).then(res => {
        if (!res.data.redirectUrl) return alert('Password approved - but no redirectUrl present, auth-flow aborted')
        window.location = res.data.redirectUrl
        // set button to active when action is done
        this.debounced = false
      }, () => {
        // set button to active when action is done, then write error message
        this.debounced = false
        this.passwordError = 'Innlogging mislyktes'
      })
    },
    forgottenPassword () {
      window.location.href = 'https://portal.momsol.no/Sektions/Administration/Bruger/SkiftAdgangskode.aspx?lang=no&email=' + this.getSelectedUserEmail
      // set button to disabled if action is running
      this.debounced = true
    },
    backToEmail () {
      // go back to enter email component
      this.$store.dispatch('setToEnterEmailStep')
    }
  },
  computed: {
    ...mapGetters(['getSelectedUserEmail'])
  }
}
</script>

<style scoped>
.underlined {
  text-decoration: underline;
}
</style>
