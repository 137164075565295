<template lang="pug">
  div.enterForgottenPasswordComponent
    v-card-title {{'Glemt passordet ditt?'}}
    v-card-subtitle.py-0 {{ getSelectedUserEmail }}
    v-card-text.mt-4
      p {{'Klikk på "Send e-post for tilbakestilling av passord" for å motta en e-post med instruksjoner om hvordan du tilbakestiller passordet . Det kan hende du må sjekke spam-mappen'}}
    v-card-actions.px-4.pb-4
      v-btn.px-4(small rounded elevation="0" color="primary" @click="backToPassword") {{'Tilbake'}}
      div.flex-grow-1
      v-btn.px-4(small rounded elevation="0" color="primary" @click="loginto") {{'Send tilbakestill passord mail'}}
      v-snackbar.text-center(v-model="alert" top right multi-line :color="alertColor") {{ alertText }}
        v-btn(text @click="alert = false") Close
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'forgotten-password',
  components: {
  },
  data: function () {
    return {
      debounced: false,
      passwordError: '',
      password: '',
      alert: false,
      alertText: '',
      alertColor: 'info'
    }
  },
  methods: {
    loginto () {
      // set button to disabled if action is running
      this.debounced = true
      this.$store.dispatch('sendResetPasswordEmail').then(res => {
        // this.$router.push({ path: '/landingPage' })
        this.alertColor = 'info'
        this.alert = true
        this.alertText = 'Mailen er sendt til den angivet adresse'

        // set button to active when action is done
        this.debounced = false
      }, () => {
        this.alertColor = 'error'
        this.alert = true
        this.alertText = 'Der opstod en fejl, kontakt supporten for hjælp.'
        // set button to active when action is done, then write error message
        this.debounced = false
        this.passwordError = 'Innlogging mislyktes'
      })
    },
    backToPassword () {
      // go back to enter email component
      this.$store.dispatch('setToEnterPasswordStep')
    }
  },
  computed: {
    ...mapGetters(['getSelectedUserEmail'])
  }
}
</script>

<style scoped>
</style>
