<template lang="pug">
  v-container.backgroundImage.text-center(fluid fill-height :class="getUserLanguageId")
    v-toolbar.languageBar(absolute dense flat)
    v-layout(row wrap align-center)
      v-flex
        v-card.componentCard.mx-auto.text-align-left
          v-card-title
            img.logo(src="../assets/Momentum_favicon-01_black.png")
          v-form.enterResetPasswordComponent(@submit.prevent="submitHandler")
            v-card-subtitle.py-0 {{ email }}
            v-card-title {{$t('Reset your password.')}}
            v-card-text
              v-text-field(
                  :label="'Passord'"
                  v-model='password'
                  type="password"
                  :rules="initialRule"
                  :error-messages="passwordError"
                  :placeholder="'Skriv inn passord'"
                  autofocus
              )
              v-text-field(
                  :label="$t('Confirm password')"
                  v-model='confirmpassword'
                  type="password"
                  :rules="confirmRule"
                  :error-messages="passwordError"
                  :placeholder="$t('Confirm password')"
                  autofocus
                  @keyup.enter="loginto"
              )
            v-card-actions.px-4.pb-4
              div(class="flex-grow-1")
              v-btn.px-4(small rounded elevation="0" type="submit" color="primary" class="loginbtn" @click="loginto" :disabled="debounced") {{$t('Gem')}}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'login',
  components: { },
  data: function () {
    return {
      email: this.$route.query.user,
      password: '',
      confirmpassword: '',
      code: this.$route.query.code,
      passwordError: '',
      debounced: false,
      parameters: []
    }
  },
  watch: {
    'password' (val) {
      this.initialRule = []
    },
    'confirmpassword' (val) {
      this.confirmRule = []
    }
  },
  methods: {
    changeLanguage (languageId) {
      this.$store.dispatch('setUserLanguageId', languageId)
      this.$router.push({ query: { lang: languageId } }).catch(e => { })
    },
    nextStep: function () {},
    loginto () {
      // set button to disabled if action is running
      this.debounced = true
      // const code = this.$route.query.code
      this.$store.dispatch('setResetPassword', { email: this.$route.query.user, password: this.password, confirmpassword: this.confirmpassword, code: this.code }).then(res => {
        // console.log(res)
        alert('Password changed succesfully')
        this.$router.push('loginPage')
        // set button to active when action is done
        this.debounced = false
      }, reason => {
        // console.log(reason)
        // set button to active when action is done, then write error message
        this.debounced = false
        this.initialRule = [
          // return message if rules for password input is not followed
          value => !!value || 'Nødvendig',
          value => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
            return (
              pattern.test(value) || this.$i18n.t('Min. 8 characters with at least one capital letter, a number and a special character.')
            )
          }
        ]
        this.confirmRule = [
          // return message if rules for password input is not followed
          value => !!value || 'Nødvendig',
          value => {
            return (this.password === this.confirmpassword) || this.$i18n.t('Password must match')
          }
        ]
      })
    }
  },
  computed: {
    ...mapGetters(['getUserLanguageId'])
  },
  beforeCreate () {
    // send language query to state cahnge.
    if (this.$route.query.lang) {
      this.$store.dispatch('setUserLanguageId', 'this.$route.query.lang')
    }
  }
}
</script>

<style scoped>
.backgroundImage {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.dk {
  background-image: url("../assets/envidan_dk_background.jpg");
}
.no {
  background-image: url("../assets/envidan_no_background.jpg");
}
.se {
  background-image: url("../assets/envidan_se_background.jpg");
}
.en {
  background-image: url("../assets/envidan_en_background.jpg");
}
.languageBar {
  right: 0;
  background: transparent !important;
}
.dk .langBtn, .no .langBtn{
  color: #fff;
}
.se .langBtn, .en .langBtn{
  color: #0c1f39;
}
.componentCard {
  width: 500px;
}
.text-align-left{
  text-align: left;
}
.logo{
  height: 40px;
}
</style>
