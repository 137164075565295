<template lang="pug">
  v-container.backgroundImage.text-center(fluid fill-height :class="getUserLanguageId")
    v-toolbar.languageBar(absolute dense flat)
    v-layout(row wrap align-center)
      v-flex
        v-card.componentCard.mx-auto.text-align-left
          v-card-title
            img.logo(src="../assets/Momentum_favicon-01_black.png")
          component(:is="activeStep")
    v-snackbar(v-model="cookieAlert" bottom right multi-line vertical color="rgba(0, 0, 0, 0.5)" :timeout="timeout")
      p Ved klik på ok eller videre brug af vores portal accepterer du brugen af cookies.
      v-expansion-panels(flat dark dense popout)
        v-expansion-panel
          v-expansion-panel-header klik her for at se brugen af cookies.
          v-expansion-panel-content
            v-list-item(two-line dense)
              v-list-item-content
                v-list-item-title Sikkerhed
                v-list-item-subtitle Der giver godkendelse til brugere.
            v-list-item(two-line dense)
              v-list-item-content
                v-list-item-title Sessioner
                v-list-item-subtitle Der midlertidigt husker dine sidste valg.
            v-list-item(two-line dense)
              v-list-item-content
                v-list-item-title Analytics
                v-list-item-subtitle Der anonymt indsamler brugeradfærd.
            v-list-item(two-line dense)
              v-list-item-content
                v-list-item-title Præferencer
                v-list-item-subtitle Der husker dit sprog og andre indstillinger.
      v-btn(text rounded @click="cookieAccept") OK
</template>

<script>
import * as loginSteps from '@/components/loginSteps'
import { mapGetters } from 'vuex'

export default {
  name: 'login',
  components: {
    ...loginSteps
  },
  data: function () {
    return {
      timeout: 0
    }
  },
  methods: {
    changeLanguage (languageId) {
      this.$store.dispatch('setUserLanguageId', languageId)
      this.$router.push({ query: { lang: languageId } }).catch(e => { })
    },
    cookieAccept () {
      this.$store.dispatch('setAcceptCookie')
      return this.cookieAlert
    }
  },
  computed: {
    // set the active component
    activeStep: function () {
      return this.$store.state.activeComponent
    },
    cookieAlert: function () {
      return this.$store.state.cookieAlert
    },
    ...mapGetters(['getUserLanguageId', 'getCookieAlert'])
  },
  beforeCreate () {
    // set initial active component to password, if cookie exist
    this.$store.dispatch('loginState')
    this.$store.dispatch('setReturnUrl', { returnUrl: this.$route.query.ReturnUrl })
    // send language query to state cahnge.
    if (this.$route.query.lang) {
      this.$store.dispatch('setUserLanguageId', this.$route.query.lang)
    }
  }
}
</script>

<style scoped>
.backgroundImage {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.no {
  background-image: url("../assets/envidan_no_background.jpg");
}
.languageBar {
  right: 0;
  background: transparent !important;
}
.dk .langBtn, .no .langBtn{
  color: #fff;
}
.se .langBtn, .en .langBtn{
  color: #0c1f39;
}
.componentCard {
  width: 500px;
}
.text-align-left{
  text-align: left;
}
.logo{
  height: 40px;
}
</style>
