export default {
  SET_COOKIE_ALERT: 'SET_COOKIE_ALERT',
  SET_USER_LANGUAGEID: 'SET_USER_LANGUAGEID',
  SET_SELECTED_USER_EMAIL: 'SET_SELECTED_USER_EMAIL',
  SET_TO_ENTER_EMAIL_STEP: 'SET_TO_ENTER_EMAIL_STEP',
  SET_TO_ENTER_PASSWORD_STEP: 'SET_TO_ENTER_PASSWORD_STEP',
  SET_TO_ENTER_FORGOTTEN_PASSWORD_STEP: 'SET_TO_ENTER_FORGOTTEN_PASSWORD_STEP',
  SET_ACCEPT_COOKIE: 'SET_ACCEPT_COOKIE',
  SET_EMAIL_COOKIE: 'SET_EMAIL_COOKIE',
  SET_LANG_COOKIE: 'SET_LANG_COOKIE',
  SET_RETURNURL: 'SET_RETURNURL'
}
