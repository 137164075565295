<template lang="pug">
  div.enterEmailComponent
    v-card-title {{ 'Logg på' }}
    v-card-text
      v-text-field.emailTextField(
        :label="'E-post'"
        v-model='email'
        :rules="rules.required"
        :error-messages="unknownEmail"
        :placeholder="'Skriv inn e-posten din'"
        @keyup.enter="nextStep"
      )
      v-checkbox.mt-1(v-model="rememberMe" :label="'Husk meg'" hide-details)
    v-card-actions.px-4.pb-4
      div.flex-grow-1
      v-btn.loginbtn.px-4(small rounded elevation="0" type="submit" color="primary" @click="nextStep" :disabled="debounced") {{'Neste'}}
</template>

<script>
export default {
  name: 'enter-email',
  components: {
  },
  data: function () {
    return {
      email: '',
      rememberMe: false,
      debounced: false,
      unknownEmail: undefined,
      rules: {
        // return message if rules for email input is not followed
        required: value => !!value || 'Nødvendig'
      }
    }
  },
  methods: {
    nextStep: function () {
      // set button to disabled if action is running
      this.debounced = true
      // check if email exist
      this.$store.dispatch('checkSelectedUserEmail', { email: this.email }).then(res => {
        // set typed email on state and add cookie if checkbox true
        this.$store.dispatch('setSelectedUserEmail', { email: this.email, rememberMe: this.rememberMe })
        // set button to active when action is done
        this.debounced = false
      }, () => {
        // set button to active when action is done, then write error message
        this.debounced = false
        this.unknownEmail = 'Ukjent e-post'
      })
    }
  },
  computed: {
  }
}
</script>

<style scoped>
</style>
