import axios from 'axios'
import app from '../main' // import the instance
import qs from 'qs'

function createApi () {
  axios.defaults.withCredentials = true // withCredentials to allow cross-site Access-Control (cookies or auth-headers)
  const api = axios.create({
    baseURL: 'api/',
    // timeout: 5000,
    // s
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })

  api.interceptors.request.use(config => {
    app.$Progress.start() // for every request start the progress
    return config
  })

  api.interceptors.response.use(response => {
    app.$Progress.finish() // finish when a response is received
    return response
  })

  return api
}
export default createApi()
