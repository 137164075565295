import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { mapGetters } from 'vuex'
import vuetify from './plugins/vuetify'
import { sync } from 'vuex-router-sync'
import { i18n } from './i18n/i18n-setup'
import VueProgressBar from 'vue-progressbar'

Vue.config.productionTip = false

// eslint-disable-next-line
const unsync = sync(store, router) // done. Returns an unsync callback fn
const progressOptions = {
  color: '#00aeef',
  failedColor: '#d82821',
  thickness: '8px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

Vue.use(VueProgressBar, progressOptions)

export default new Vue({
  router,
  store,
  i18n,
  vuetify,
  mapGetters,
  VueProgressBar,
  render: h => h(App)
}).$mount('#app')
