import Vue from 'vue'
import Router from 'vue-router'
import loginPage from './views/loginPage.vue'
import resetPasswordPage from './views/resetPasswordPage.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history', // add 'history' mode to be able to read query params
  routes: [
    {
      path: '/',
      name: 'loginPage',
      component: loginPage
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: resetPasswordPage
    }
  ]
})

export default router
