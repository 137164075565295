import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00aeef',
        secondary: '#ccc',
        accent: '#00aeef',
        error: '#d82821',
        info: '#00aeef',
        success: '#73d821',
        warning: '#d82821'
      }
    }
  }
})
